import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import classes from './SideNavigationLink.scss';
import { SideNavigationMenuLink } from '../SideNavigation.types';
import { AreColorsInvertedContext, IsSideNavigationOpenContext } from '../SideNavigation.utils';
import { useTranslate } from '../../../redux/slices/translations';
import { Pill } from '../../../components/pill/Pill';

type Props = {
	item: SideNavigationMenuLink;
	inGroup?: boolean;
};

export const SideNavigationLink = ({ item, inGroup }: Props) => {
	const translate = useTranslate();
	const isSideNavigationOpen = useContext(IsSideNavigationOpenContext);
	const areColorsInverted = useContext(AreColorsInvertedContext);
	const history = useHistory();
	const { theme } = useTheme();

	const isActive = item.isActive ? item.isActive(history.location.pathname) : false;

	return (
		<li>
			<NavLink
				to={item.external ? { pathname: item.path } : item.path}
				target={item.external ? '_blank' : undefined}
				className={classnames(classes.item, {
					[classes.invertColors]: areColorsInverted,
					[classes.slim]: !isSideNavigationOpen,
					[classes.active]: isActive,
					[classes.inGroup]: inGroup,
				})}
				activeClassName={classes.active}
			>
				{item.icon && (
					<span
						data-testid="icon"
						className={classes.icon}
						style={{ maskImage: `url(${item.icon})`, WebkitMaskImage: `url(${item.icon})` }}
					/>
				)}
				<span className={classes.text}>{translate(item.title)}</span>
				{item.pill && (
					<div className={classnames(theme, classes.pillContainer)}>
						<Pill type={item.pill} />
					</div>
				)}
			</NavLink>
		</li>
	);
};
