import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';

const shouldFetchDirectDials = (state: ReduxState) =>
	!state.directDials.fetched && !state.directDials.fetching;

export const fetchDirectDials = createAsyncThunk(
	'directDials/fetch',
	async () => api.getDirectDials(),
	{
		condition: (_arg, { getState }) => shouldFetchDirectDials(getState()),
	}
);

export const createDirectDial = createAsyncThunk(
	'directDials/create',
	async (data: { directDial: string; endpointId: string }) =>
		api.createDirectDial(data.directDial, data.endpointId)
);

export const deleteDirectDial = createAsyncThunk(
	'directDials/delete',
	async (data: { directDial: string }) => api.deleteDirectDial(data.directDial)
);

export const editDirectDial = createAsyncThunk(
	'directDials/edit',
	async (data: { currentDirectDial: string; newDirectDial: string; endpointId: string }) =>
		api.editDirectDial(data.currentDirectDial, data.newDirectDial, data.endpointId)
);
