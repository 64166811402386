import React from 'react';
import classes from './PureCommunicationButtons.scss';

interface ExternalProps {
	onCallButtonClicked?(): void;
	onSmsButtonClicked?(): void;
	onFaxButtonClicked?(): void;
	translate(languageKey: string): string;
}

class PureCommunicationButtons extends React.PureComponent<ExternalProps> {
	private renderCallButton() {
		return (
			<button type="button" onClick={this.onCallButtonClicked}>
				{this.props.translate('COMMUNICATIONS_CALL_BUTTON')}
			</button>
		);
	}

	private renderSmsButton() {
		return (
			<button type="button" onClick={this.onSmsButtonClicked}>
				{this.props.translate('COMMUNICATIONS_SMS_BUTTON')}
			</button>
		);
	}

	private renderFaxButton() {
		return (
			<button type="button" onClick={this.onFaxButtonClicked}>
				{this.props.translate('COMMUNICATIONS_FAX_BUTTON')}
			</button>
		);
	}

	private onCallButtonClicked = (event: React.MouseEvent): void => {
		event.preventDefault();
		if (this.props.onCallButtonClicked) {
			this.props.onCallButtonClicked();
		}
	};

	private onSmsButtonClicked = (event: React.MouseEvent): void => {
		event.preventDefault();
		if (this.props.onSmsButtonClicked) {
			this.props.onSmsButtonClicked();
		}
	};

	private onFaxButtonClicked = (event: React.MouseEvent): void => {
		event.preventDefault();
		if (this.props.onFaxButtonClicked) {
			this.props.onFaxButtonClicked();
		}
	};

	public render() {
		return (
			<div className={classes.pureCommunicationButtonsContainer}>
				{this.props.onCallButtonClicked ? this.renderCallButton() : null}
				{this.props.onSmsButtonClicked ? this.renderSmsButton() : null}
				{this.props.onFaxButtonClicked ? this.renderFaxButton() : null}
			</div>
		);
	}
}

export default PureCommunicationButtons;
