import React from 'react';
import { Button, ContextMenu, MenuItem } from '@panda/ui';
import moment from 'moment/moment';
import classnames from 'classnames';
import classes from './AppWidget.scss';
import { Widget } from '../../../../../../components/widget/Widget';
import BrandLogo from '../../../../../../media/logo/sipgate_bildmarke.svg';
import {
	AppSubscriptionName,
	hasActiveBookingByType,
	isCanceled,
	isCanceledTrial,
	isSipgateAppWithSubscription,
	isSipgateFreeApp,
	isTerminated,
	isTrial,
	useSipgateApp,
} from '../../../../../../redux/slices/sipgateApp';
import { useDialogs } from '../../../../../../routes/paths/dialogs';
import { getLanguageKeyForSubscriptionName } from '../../dialogs/utils';
import { mixpanel } from '../../../../../../third-party/mixpanel';
import Spinner from '../../../../../../components/spinner/Spinner';
import { hasRestriction, useUserRestrictions } from '../../../../../../redux/modules/restrictions';
import { useTranslate } from '../../../../../../redux/slices/translations';

interface ExternalProps {
	userId: string;
}

type Props = ExternalProps;

const AppWidget = ({ userId }: Props) => {
	const translate = useTranslate();
	const dialogs = useDialogs();

	const { fetched: sipgateAppFetched, data: sipgateApp } = useSipgateApp(userId);
	const userRestrictions = useUserRestrictions(userId, ['CAN_USE_SIPGATE_APP']);
	const canUseSipgateApp = hasRestriction(userRestrictions, 'CAN_USE_SIPGATE_APP', userId);

	if (!sipgateApp || !canUseSipgateApp) {
		return null;
	}

	if (!sipgateAppFetched) {
		return (
			<Widget headline={translate('APP_WIDGET_HEADING')} fancy>
				<div className={classnames(classes.container)}>
					<Spinner className={classes.spinner} />
				</div>
			</Widget>
		);
	}

	const openBookAppDialog = () => {
		dialogs.bookApp.open({ webuserId: userId });

		mixpanel.track('CTA clicked', {
			CTA_ID: 'APP_FREE_TRIAL_BUTTON_CLICKED',
		});
	};

	const renderSubscriptionStatus = () => {
		let description = '';

		if (isSipgateAppWithSubscription(sipgateApp) && isTrial(sipgateApp)) {
			if (isCanceledTrial(sipgateApp)) {
				description = translate(
					'APP_WIDGET_BOOKING_STATE_TRIAL_CANCELLED_DESCRIPTION',
					moment(sipgateApp.activeBooking.trial!.endsAt).format('DD.MM.')
				);
			} else {
				description = translate(
					'APP_WIDGET_BOOKING_STATE_ACTIVE_TRIAL_DESCRIPTION',
					moment(sipgateApp.activeBooking.trial!.endsAt).format('DD.MM.')
				);
			}
		}

		if (isSipgateAppWithSubscription(sipgateApp) && isTerminated(sipgateApp)) {
			description = translate(
				'APP_WIDGET_BOOKING_STATE_TERMINATED_DESCRIPTION',
				moment(sipgateApp.activeBooking.terminatedFor).format('DD.MM.')
			);
		}

		if (description === '') return <></>;

		return <div className={classes.description}>{description}</div>;
	};

	const renderUpgradeButton = () => {
		return (
			<Button action="trigger" onClick={() => openBookAppDialog()} size="small" deprecated>
				{translate('APP_WIDGET_BOOKING_UPGRADE_BUTTON')}
			</Button>
		);
	};

	const renderSubscriptionName = () => {
		// TODO: clean up when the description is no longer prefixed with "CLINQ"
		return translate(
			getLanguageKeyForSubscriptionName(
				sipgateApp.activeBooking.subscriptionName as AppSubscriptionName
			)
		).replace('CLINQ', '');
	};

	const renderBookTrialMenuItem = () => {
		return (
			<MenuItem onClick={() => openBookAppDialog()}>
				{translate('APP_WIDGET_MENU_UPGRADE')}
			</MenuItem>
		);
	};

	const renderContextMenuUpgrade = () => {
		return (
			<MenuItem onClick={() => dialogs.upgradeApp.open({ webuserId: sipgateApp.userId })}>
				{translate('APP_WIDGET_MENU_UPGRADE')}
			</MenuItem>
		);
	};

	const renderCancellationMenuItem = () => {
		if (isSipgateAppWithSubscription(sipgateApp)) {
			if (isCanceledTrial(sipgateApp) || isCanceled(sipgateApp)) {
				return (
					<MenuItem onClick={() => dialogs.revokeCancelApp.open({ webuserId: sipgateApp.userId })}>
						{translate('APP_WIDGET_MENU_REVOKE_CANCELLATION')}
					</MenuItem>
				);
			}
			return (
				<MenuItem critical onClick={() => dialogs.cancelApp.open({ webuserId: sipgateApp.userId })}>
					{translate('APP_WIDGET_MENU_CANCEL_SUBSCRIPTION')}
				</MenuItem>
			);
		}
		return <></>;
	};

	const renderContextMenu = () => {
		return (
			<ContextMenu size="medium" variant="quiet">
				{isSipgateAppWithSubscription(sipgateApp) ? <></> : renderBookTrialMenuItem()}
				<MenuItem onClick={() => dialogs.downloadApp.open()}>
					{translate('APP_WIDGET_MENU_DOWNLOAD_APP')}
				</MenuItem>
				{hasActiveBookingByType(sipgateApp, 'PRO') ? renderContextMenuUpgrade() : <></>}
				{renderCancellationMenuItem()}
			</ContextMenu>
		);
	};

	const renderWithContextMenuAndSubline = () => {
		return (
			<div className={classes.withContextMenuAndSubline}>
				<div>
					<div className={classes.headline}>{translate('APP_WIDGET_HEADLINE_APP_CLINQ')}</div>
					<div className={classes.subline}>
						{isSipgateFreeApp(sipgateApp) ? renderUpgradeButton() : renderSubscriptionName()}
						{renderSubscriptionStatus()}
					</div>
				</div>
				<div className={classes.contextMenu}>{renderContextMenu()}</div>
			</div>
		);
	};

	const renderContent = () => {
		const additionalClasses = {
			[classes.cancelled]:
				isSipgateAppWithSubscription(sipgateApp) &&
				(isCanceledTrial(sipgateApp) || isTerminated(sipgateApp)),
			[classes.activeBooking]:
				isSipgateAppWithSubscription(sipgateApp) &&
				!(isCanceledTrial(sipgateApp) || isTerminated(sipgateApp)),
		};

		return (
			<>
				<div className={classnames(classes.container, additionalClasses)}>
					<img src={BrandLogo} alt="" />
					{renderWithContextMenuAndSubline()}
				</div>
			</>
		);
	};

	return (
		<Widget headline={translate('APP_WIDGET_HEADING')} fancy>
			{renderContent()}
		</Widget>
	);
};

export default AppWidget;
