import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { AccountEvent, discardEvent, fetchEvents } from '../../redux/modules/events';
import Snackbar, { SnackbarSeverity } from './Snackbar';
import { ReduxState } from '../../redux/types';
import { instanaLog } from '../../third-party/instana';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

const notAllowedEventNames = ['WELCOMECOMMAND'];

const mapStateToProps = (state: ReduxState) => ({
	events: state.events.items,
});

const mapDispatchToProps = {
	fetchEvents,
	discardEvent,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & TranslateProps;

class EventSnackbar extends React.PureComponent<Props> {
	public componentDidMount() {
		this.props.fetchEvents();
	}

	private getSeverityLevel = (event: AccountEvent): SnackbarSeverity => {
		const knownSevereties = ['info', 'warning', 'error', 'success'];

		return (knownSevereties.find(s => event.payload.severityLevel.toLowerCase() === s) ||
			'info') as SnackbarSeverity;
	};

	private shouldEventAutoHide = (event: AccountEvent) => {
		return event.payload.severityLevel !== 'ERROR';
	};

	private getLanguageKeyForFeature = (featureId: string): string => {
		const featureLanguageKeys = new Map([
			['1CT-TARIF', 'FEATUREPAGE_1CT-TARIF_BOOKED'],
			['ABSENDERNUMMER', 'FEATUREPAGE_ABSENDERNUMMER_BOOKED'],
			['DE-ALLNET-FLAT', 'FEATUREPAGE_DE-ALLNET-FLAT_BOOKED'],
			['EU-PLUS-ALLNET-FLAT', 'FEATUREPAGE_EU-PLUS-ALLNET-FLAT_BOOKED'],
			['EUROPA-FLAT', 'FEATUREPAGE_EUROPA-FLAT_BOOKED'],
			['FAXANSCHLUSS-MIGRATION', 'FEATUREPAGE_FAXANSCHLUSS-MIGRATION_BOOKED'],
			['FAXANSCHLUSS', 'FEATUREPAGE_FAXANSCHLUSS_BOOKED'],
			['FEATURE_ALREADY', 'FEATUREPAGE_FEATURE_ALREADY_BOOKED'],
			['FORWARDING', 'FEATUREPAGE_FORWARDING_BOOKED'],
			['PORTIERUNG-MOBILRUFNUMMER', 'FEATUREPAGE_PORTIERUNG-MOBILRUFNUMMER_BOOKED'],
			['PORTIERUNG-ORTSRUFNUMMER', 'FEATUREPAGE_PORTIERUNG-ORTSRUFNUMMER_BOOKED'],
			['TARIFANSAGE', 'FEATUREPAGE_TARIFANSAGE_BOOKED'],
			['VOICEMAIL', 'FEATUREPAGE_VOICEMAIL_BOOKED'],
			['VOIP-FLATRATE-BASIC', 'FEATUREPAGE_VOIP-FLATRATE-BASIC_BOOKED'],
			['VOIP-FLATRATE-EUROPA', 'FEATUREPAGE_VOIP-FLATRATE-EUROPA_BOOKED'],
			['VOIP-FLATRATE-PLUS', 'FEATUREPAGE_VOIP-FLATRATE-PLUS_BOOKED'],
		]);
		const maybeLanguageKey = featureLanguageKeys.get(featureId);
		if (maybeLanguageKey) {
			return maybeLanguageKey;
		}
		return 'FEATUREPAGE_DEFAULT_SUCCESS';
	};

	private getEventSnackbarText(event: AccountEvent) {
		if (!event) {
			return '';
		}

		const params = { ...event.payload, feature: '' };
		if (event.payload.featureNameLk) {
			params.feature = this.props.translate(event.payload.featureNameLk);
		}

		if (event.eventName === 'FEATURE_BOOK_SUCCESS') {
			if (event.payload.featureId) {
				const featureSuccessText = this.props.translate(
					this.getLanguageKeyForFeature(event.payload.featureId.toUpperCase()),
					params
				);

				if (featureSuccessText) {
					return featureSuccessText;
				}
			}
		}

		try {
			return this.props.translate(event.eventName, params);
		} catch (e) {
			instanaLog.error(`Failed to render LK ${event.eventName}:`, e);
			return '';
		}
	}

	private handleSnackbarClose = (event: AccountEvent) => () => {
		this.props.discardEvent(event);
	};

	private isSnackbarEvent = (event: AccountEvent) => {
		return (
			!event.dialogEvent &&
			event.eventType !== 'TOUR' &&
			event.eventType !== 'CTA' &&
			!notAllowedEventNames.includes(event.eventName)
		);
	};

	public render() {
		const event = this.props.events.find(this.isSnackbarEvent);
		const message = event ? this.getEventSnackbarText(event) : null;

		return (
			<Snackbar
				snackbarOpen={!!event}
				closeSnackbar={event ? this.handleSnackbarClose(event) : () => {}}
				severity={event ? this.getSeverityLevel(event) : 'info'}
				autoHideDuration={event && this.shouldEventAutoHide(event) ? 3500 : 0}
			>
				<span>{message}</span>
			</Snackbar>
		);
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(EventSnackbar));
