import { DateTime } from 'luxon';
import { CallStatus, ChannelHistoryItem } from './types';

export const crunchNumbers = (
	history: ChannelHistoryItem[],
	callStatus: CallStatus
): Record<string, number> => {
	const result = history
		.filter(item => item.callStatus === callStatus)
		.filter(item => DateTime.fromISO(item.datetime, { zone: 'UTC' }) <= DateTime.now())
		.filter(
			item =>
				DateTime.fromISO(item.datetime, { zone: 'UTC' }) >= DateTime.now().minus({ hours: 25 })
		)
		.reduce(
			(acc, item) => {
				const key = DateTime.fromISO(item.datetime, { zone: 'UTC' }).startOf('hour').toISO();

				acc[key] = (acc[key] || 0) + 1;

				return acc;
			},
			{} as Record<string, number>
		);

	const filledResult: Record<string, number> = {};
	const now = DateTime.now().setZone('UTC');

	[...Array(25)].forEach((_, index) => {
		const currentHour = now.minus({ hours: index }).startOf('hour').toISO();
		filledResult[currentHour] = result[currentHour] || 0;
	});

	return filledResult;
};

export const randomString = (length: number) =>
	Math.random()
		.toString(36)
		.substring(2, 2 + length);
