// errors

interface ErrorBase<T extends string> {
	name: T;
	message: string;
	channelId: string;
}

export type ChannelHistoryError = ErrorBase<'COULD_NOT_FETCH_HISTORY'>;

export const CouldNotFetchHistory = (channelId: string): ChannelHistoryError => ({
	name: 'COULD_NOT_FETCH_HISTORY',
	message: 'Could not fetch history',
	channelId,
});

export const isChannelHistoryError = (error: unknown): error is ChannelHistoryError =>
	typeof error === 'object' && error !== null && 'name' in error && 'message' in error;

export type CallStatus = 'Accepted' | 'Missed';

export type ChannelHistoryItem = {
	acdName: string;
	callCenterNumber: string;
	callerNumber: string;
	callStatus: CallStatus;
	datetime: string;
	waitingTime: string;
	talkTime: string;
	followUpTime: string;
	missedReason: string;
	acceptedAgentName: string;
};

export type ChannelHistoryState = {
	items: Record<string, ChannelHistoryItem[]>;
	fetching: string[];
	fetched: Record<string, string>;
	errors: Record<string, ChannelHistoryError>;
	revision: string;
};

export type ChannelHistoryAggregateData = {
	id: string;
	data: {
		hours: number[];
		callAmounts: number[];
	};
};
