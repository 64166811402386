import ReactJoyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step, Styles } from 'react-joyride';
import React, { Component } from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { Translate } from '../../redux/slices/translations';
import { mixpanel } from '../../third-party/mixpanel';
import { JoyrideTooltip } from './JoyrideTooltip';

const styles: Styles = {
	overlay: {
		mixBlendMode: 'darken',
	},
	spotlight: {
		backgroundColor: 'white',
	},
	options: {
		zIndex: 10000,
		arrowColor: 'var(--neo-color-web-app-component-card-background-default)',
	},
};

interface ComponentProps {
	translate: Translate;
	steps: Step[];
	onDone: () => void;
	callback?: (data: CallBackProps) => void;
	disableScrolling?: boolean;
	disableScrollParentFix?: boolean;
	hideCloseButton?: boolean;
	hideBackButton?: boolean;
}

interface ComponentPropsWithTracking {
	enableTracking: true;
	name: string;
}
interface ComponentPropsWithoutTracking {
	enableTracking: false;
	name?: undefined;
}

type FinalProps = ComponentProps & (ComponentPropsWithoutTracking | ComponentPropsWithTracking);

interface ComponentState {
	run: boolean;
}

class JoyrideTour extends Component<FinalProps, ComponentState> {
	public static defaultProps = {
		enableTracking: false,
	};

	private finished = false;

	private animationFrameRequest = -1;

	private watcher?: DebouncedFunc<() => void>;

	private stepIndex = 0;

	public state = {
		run: false,
	};

	public componentDidMount(): void {
		this.waitUntilTargetElementIsRendered();
	}

	public componentWillUnmount(): void {
		this.cancelAnimationFrame();
	}

	private waitUntilTargetElementIsRendered() {
		this.setState({ run: false });
		this.watcher = debounce(this.requestAnimationFrame.bind(this), 300, { maxWait: 1000 });
		this.requestAnimationFrame();
	}

	private checkIfElementIsRendered(stepIndex: number) {
		if (document.querySelector(this.props.steps[stepIndex].target as string)) {
			this.cancelAnimationFrame();
			this.setState({ run: true });
		}
	}

	private requestAnimationFrame() {
		this.checkIfElementIsRendered(this.stepIndex);
		if (this.watcher) {
			this.animationFrameRequest = window.requestAnimationFrame(this.watcher);
		}
	}

	private cancelAnimationFrame() {
		window.cancelAnimationFrame(this.animationFrameRequest);
		if (this.watcher) {
			this.watcher.cancel();
			this.watcher = undefined;
		}
	}

	private track(step: number, size: number, action: string, status: string) {
		if (this.props.enableTracking) {
			mixpanel.track('Clicked joyride', {
				'Joyride Name': this.props.name,
				'Joyride Step': `${step + 1}/${size}`,
				'Joyride Action': action,
				'Joyride Status': status,
			});
		}
	}

	public render() {
		const locale = {
			back: this.props.translate('TOURGUIDE_BACK'),
			last: this.props.translate('TOURGUIDE_LAST'),
			next: this.props.translate('TOURGUIDE_NEXT'),
			skip: this.props.translate('TOURGUIDE_SKIP'),
		};

		return (
			<ReactJoyride
				tooltipComponent={JoyrideTooltip}
				hideCloseButton={this.props.hideCloseButton}
				disableScrolling={this.props.disableScrolling}
				disableScrollParentFix={this.props.disableScrollParentFix}
				steps={this.props.steps}
				run={this.state.run}
				continuous
				styles={styles}
				showSkipButton
				showProgress={this.props.steps.length > 1}
				disableOverlayClose
				floaterProps={{ disableAnimation: true }}
				locale={locale}
				callback={(callbackProps: CallBackProps) => {
					if (this.props.enableTracking && callbackProps.lifecycle === 'complete') {
						switch (callbackProps.action) {
							case ACTIONS.CLOSE:
							case ACTIONS.PREV:
							case ACTIONS.NEXT:
							case ACTIONS.SKIP:
								this.track(
									callbackProps.index,
									callbackProps.size,
									callbackProps.action,
									callbackProps.status
								);
								break;
						}
					}

					if (
						callbackProps.action === ACTIONS.CLOSE ||
						callbackProps.status === STATUS.SKIPPED ||
						callbackProps.status === STATUS.FINISHED
					) {
						if (!this.finished) {
							this.finished = true;
							this.props.onDone();
						}
					}

					if (
						callbackProps.type === EVENTS.STEP_AFTER &&
						callbackProps.action === ACTIONS.NEXT &&
						callbackProps.index !== callbackProps.size - 1
					) {
						this.stepIndex = callbackProps.index + 1;
						this.waitUntilTargetElementIsRendered();
					}

					if (this.props.callback) {
						this.props.callback(callbackProps);
					}
				}}
				hideBackButton={this.props.hideBackButton}
			/>
		);
	}
}

export default JoyrideTour;
