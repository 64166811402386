import React from 'react';
import { Checkbox } from '@panda/ui';

import classes from './MarkingsFilter.scss';
import { Translate } from '../../../redux/slices/translations';
import { ChangeUrlParameter } from '../selections/types';
import { ReadType, StarredType } from '../selections/parsers';
import { FilterDropdown } from './FilterDropdown';
import { Filter } from './Filter';

interface Props {
	open?: boolean;
	translate: Translate;
	onOpenClose?: (open: boolean) => void;

	readSelection: ReadType[];
	starredSelection: StarredType[];
	onChange: ChangeUrlParameter;
}

export class MarkingsFilter extends React.PureComponent<Props> {
	private onChangedRead = (value: ReadType) => {
		const changed = [...this.props.readSelection];

		if (this.hasReadSelected(value)) {
			changed.splice(changed.indexOf(value), 1);
			this.props.onChange({ read: changed, offset: 0 });
		} else {
			changed.push(value);
			this.props.onChange({ read: changed, offset: 0 });
		}
	};

	private onChangedStarred = (value: StarredType) => {
		const changed = [...this.props.starredSelection];

		if (this.hasStarredSelected(value)) {
			changed.splice(changed.indexOf(value), 1);
			this.props.onChange({ starred: changed, offset: 0 });
		} else {
			changed.push(value);
			this.props.onChange({ starred: changed, offset: 0 });
		}
	};

	private hasReadSelected = (value: ReadType) => this.props.readSelection.includes(value);

	private hasStarredSelected = (value: StarredType) => this.props.starredSelection.includes(value);

	private isActive() {
		return this.props.readSelection.length !== 0 || this.props.starredSelection.length !== 0;
	}

	private renderButtonLabel() {
		const selectionCount = this.props.readSelection.length + this.props.starredSelection.length;
		if (selectionCount > 1) {
			return `${this.props.translate('FILTER_MARKINGS')} (${selectionCount})`;
		}

		if (this.hasReadSelected('read')) {
			return this.props.translate('FILTER_MARKINGS_READ');
		}

		if (this.hasReadSelected('unread')) {
			return this.props.translate('FILTER_MARKINGS_UNREAD');
		}

		if (this.hasStarredSelected('starred')) {
			return this.props.translate('FILTER_MARKINGS_STARRED');
		}

		if (this.hasStarredSelected('unstarred')) {
			return this.props.translate('FILTER_MARKINGS_UNSTARRED');
		}

		return this.props.translate('FILTER_MARKINGS');
	}

	// We reorder entries on mobile to have read / unread on line one
	private renderContentWithoutDropdown() {
		return (
			<ul className={classes.filterlist}>
				<li>
					<Checkbox
						checked={this.hasReadSelected('read')}
						onChange={() => this.onChangedRead('read')}
					>
						{this.props.translate('FILTER_MARKINGS_READ')}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						checked={this.hasStarredSelected('starred')}
						onChange={() => this.onChangedStarred('starred')}
					>
						{this.props.translate('FILTER_MARKINGS_STARRED')}
					</Checkbox>
				</li>

				<li>
					<Checkbox
						checked={this.hasReadSelected('unread')}
						onChange={() => this.onChangedRead('unread')}
					>
						{this.props.translate('FILTER_MARKINGS_UNREAD')}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						checked={this.hasStarredSelected('unstarred')}
						onChange={() => this.onChangedStarred('unstarred')}
					>
						{this.props.translate('FILTER_MARKINGS_UNSTARRED')}
					</Checkbox>
				</li>
			</ul>
		);
	}

	private renderContent() {
		return (
			<ul className={classes.filterlist}>
				<li>
					<Checkbox
						checked={this.hasReadSelected('read')}
						onChange={() => this.onChangedRead('read')}
					>
						{this.props.translate('FILTER_MARKINGS_READ')}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						checked={this.hasReadSelected('unread')}
						onChange={() => this.onChangedRead('unread')}
					>
						{this.props.translate('FILTER_MARKINGS_UNREAD')}
					</Checkbox>
				</li>
				<hr />
				<li>
					<Checkbox
						checked={this.hasStarredSelected('starred')}
						onChange={() => this.onChangedStarred('starred')}
					>
						{this.props.translate('FILTER_MARKINGS_STARRED')}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						checked={this.hasStarredSelected('unstarred')}
						onChange={() => this.onChangedStarred('unstarred')}
					>
						{this.props.translate('FILTER_MARKINGS_UNSTARRED')}
					</Checkbox>
				</li>
			</ul>
		);
	}

	private renderDropdown() {
		if (!this.props.onOpenClose) {
			return null;
		}

		return (
			<FilterDropdown
				buttonLabel={this.renderButtonLabel()}
				active={this.isActive()}
				onOpenClose={this.props.onOpenClose}
				open={!!this.props.open}
			>
				{this.renderContent()}
			</FilterDropdown>
		);
	}

	public render() {
		if (this.props.onOpenClose) {
			return this.renderDropdown();
		}

		return (
			<Filter title={this.props.translate('FILTER_MARKINGS')}>
				<div className={classes.twoColumns}>{this.renderContentWithoutDropdown()}</div>
			</Filter>
		);
	}
}
