import { createSlice } from '@reduxjs/toolkit';
import { createDirectDial, deleteDirectDial, editDirectDial, fetchDirectDials } from './actions';
import { DirectDialsState } from './types';

const initialState: DirectDialsState = {
	fetched: false,
	fetching: false,
	items: [],
};

export const reducer = createSlice({
	name: 'directDials',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDirectDials.pending, state => {
			return { ...state, fetching: true };
		});

		builder.addCase(fetchDirectDials.fulfilled, (state, action) => {
			const directDials = action.payload.items;
			return {
				fetched: true,
				fetching: false,
				items: directDials.map(dd => ({
					directDial: dd.number,
					endpointId: dd.routing.targetId,
				})),
			};
		});

		builder.addCase(createDirectDial.pending, (state, action) => {
			return {
				...state,
				items: [
					...state.items,
					{
						directDial: action.meta.arg.directDial,
						endpointId: action.meta.arg.endpointId,
					},
				],
			};
		});

		builder.addCase(editDirectDial.fulfilled, (state, action) => {
			return {
				...state,
				items: [
					...state.items.filter(q => q.directDial !== action.meta.arg.currentDirectDial),
					{
						directDial: action.meta.arg.newDirectDial,
						endpointId: action.meta.arg.endpointId,
					},
				],
			};
		});

		builder.addCase(deleteDirectDial.pending, (state, action) => {
			return {
				...state,
				items: state.items.filter(q => q.directDial !== action.meta.arg.directDial),
			};
		});
	},
}).reducer;
