import { createSlice } from '@reduxjs/toolkit';

import * as actions from './actions';
import { TTSState } from './types';

const initialState: TTSState = {
	fetched: false,
	fetching: false,
	items: [],
};

export const slice = createSlice({
	name: 'acdAudioFiles',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(actions.fetchTTS.pending, state => ({
			...state,
			fetching: true,
		}));
		builder.addCase(actions.fetchTTS.fulfilled, (state, action) => ({
			...state,
			fetched: true,
			fetching: false,
			items: action.payload.items,
		}));

		builder.addCase(actions.fetchTTS.rejected, state => ({
			...state,
			fetched: false,
			fetching: false,
		}));

		builder.addCase(actions.createTTSAudioFile.fulfilled, (state, action) => ({
			...state,
			items: [...state.items, action.payload],
		}));
	},
});

export const reducer = slice.reducer;
