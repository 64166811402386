import { useEffect } from 'react';
import { ReduxState } from '../../types';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { createTTSAudioFile, fetchTTS } from './actions';
import { useAction } from '../..';

export const useTTS = (acdId: string) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchTTS(acdId));
	}, [acdId, dispatch]);

	return useSelector((state: ReduxState) => state.tts);
};

export const useTTSActions = () => ({
	fetchTTS: useAction(fetchTTS),
	createTTSAudiofile: useAction(createTTSAudioFile),
});
