import React from 'react';
import serviceUrls from '@web-apps/service-urls';
import { ExternalLink, HeadingBoundary, Headline } from '@panda/ui';
import classes from './ElectronLoginRedirect.scss';
import loginIcon from '../media/illustrations/Illustration_Softphone_Login_Code_Seite.png';
import { Translate } from '../redux/slices/translations';
import ClipboardButton from '../components/buttons/ClipboardButton';

interface ExternalProps {
	code: string;
	translate: Translate;
}

export class ElectronLoginRedirect extends React.Component<ExternalProps> {
	private readonly SOFTPHONE_LINK = `softphone://login/authenticate#code=${encodeURIComponent(
		this.props.code
	)}`;

	public componentDidMount() {
		this.openSoftphone();
	}

	private openSoftphone = () => {
		setTimeout(() => {
			const link = document.createElement('a');

			link.setAttribute('href', this.SOFTPHONE_LINK);

			link.click();
		}, 0);
	};

	private getLoginLink = () => {
		const realm = 'sipgate-apps';
		const softphoneRedirect = `${serviceUrls.authentication.accessCodeLoginUrl}&scope=offline_access`;
		const loginRedirect = `${serviceUrls.authentication.keycloak}/realms/${realm}/protocol/openid-connect/auth?client_id=desktop-app&response_type=code&redirect_uri=${softphoneRedirect}`;
		const url = `${
			serviceUrls.authentication.keycloak
		}/realms/${realm}/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(
			loginRedirect
		)}`;

		return (
			<p className={classes.loginDescription}>
				{this.props.translate.markdown.inline('SOFTPHONE_LOGIN_HELP_LINK_TEXT', url)}
			</p>
		);
	};

	public render() {
		return (
			<div className={classes.mainContainer}>
				<div className={classes.container}>
					<div className={classes.leftSide}>
						<Headline className={classes.loginHeadline}>
							{this.props.translate.markdown.inline('SOFTPHONE_LOGIN_HEADLINE')}
						</Headline>
						<HeadingBoundary>
							<p className={classes.loginDescription}>
								{this.props.translate('SOFTPHONE_LOGIN_DESCRIPTION')}
							</p>
							<div className={classes.buttonContainer}>
								<ExternalLink button size="xlarge" to={this.SOFTPHONE_LINK}>
									{this.props.translate('SOFTPHONE_LOGIN_BUTTON')}
								</ExternalLink>

								<div className={classes.clipboardInput}>
									<input
										className={classes.inputField}
										defaultValue={this.props.code}
										name="code"
									/>
									<ClipboardButton text={this.props.code} size="large" />
								</div>
							</div>
							{this.getLoginLink()}
						</HeadingBoundary>
					</div>
					<img
						className={classes.icon}
						alt={this.props.translate('SOFTPHONE_REDIRECT_ICON')}
						src={loginIcon}
					/>
				</div>
			</div>
		);
	}
}
