import React from 'react';
import Snackbar, { SnackbarSeverity } from './Snackbar';

interface Props {
	message: string;
	open: boolean;
	severity: SnackbarSeverity;
	onClose: () => void;
}

export const AutoCloseSnackbar = (props: Props) => (
	<Snackbar snackbarOpen={props.open} closeSnackbar={props.onClose} severity={props.severity}>
		{props.message}
	</Snackbar>
);
