import { useCallback } from 'react';
import { useTranslate } from '../../../../../redux/slices/translations';

export type OperatingSystem =
	| 'WINDOWS'
	| 'MAC'
	| 'LINUX_DEB'
	| 'LINUX_DEB_ARM'
	| 'LINUX_RPM'
	| 'LINUX_RPM_ARM';

export const useAppDownloadUrl = () => {
	const translate = useTranslate();

	const getOperatingSystem = useCallback((): OperatingSystem => {
		const os = navigator.userAgent;

		if (os.includes('Mac')) return 'MAC';
		if (os.includes('Linux') && os.includes('X11')) {
			return os.includes('aarch64') ? 'LINUX_DEB_ARM' : 'LINUX_DEB';
		}

		return 'WINDOWS';
	}, []);

	const getDownloadUrl = useCallback(
		(option?: OperatingSystem) => {
			const urls = {
				WINDOWS: 'BOOK_APP_DIALOG_DOWNLOAD_URL_WINDOWS',
				MAC: 'BOOK_APP_DIALOG_DOWNLOAD_URL_MACOS',
				LINUX_DEB: 'BOOK_APP_DIALOG_DOWNLOAD_URL_LINUX_DEB',
				LINUX_DEB_ARM: 'BOOK_APP_DIALOG_DOWNLOAD_URL_LINUX_DEB_ARM',
				LINUX_RPM: 'BOOK_APP_DIALOG_DOWNLOAD_URL_LINUX_RPM',
				LINUX_RPM_ARM: 'BOOK_APP_DIALOG_DOWNLOAD_URL_LINUX_RPM_ARM',
			};
			return translate(urls[option || getOperatingSystem()]);
		},
		[translate, getOperatingSystem]
	);

	const getOperatingSystemName = useCallback(
		(option?: OperatingSystem) => {
			const osNames = {
				WINDOWS: 'BOOK_APP_DIALOG_DOWNLOAD_NAME_WINDOWS',
				MAC: 'BOOK_APP_DIALOG_DOWNLOAD_NAME_MACOS',
				LINUX_DEB: 'BOOK_APP_DIALOG_DOWNLOAD_NAME_LINUX_DEB',
				LINUX_DEB_ARM: 'BOOK_APP_DIALOG_DOWNLOAD_NAME_LINUX_DEB_ARM',
				LINUX_RPM: 'BOOK_APP_DIALOG_DOWNLOAD_NAME_LINUX_RPM',
				LINUX_RPM_ARM: 'BOOK_APP_DIALOG_DOWNLOAD_NAME_LINUX_RPM_ARM',
			};
			return translate(osNames[option || getOperatingSystem()]);
		},
		[translate, getOperatingSystem]
	);

	return {
		getOperatingSystem,
		getDownloadUrl,
		getOperatingSystemName,
	};
};
