import { Store } from '@reduxjs/toolkit';
import { ChannelHistoryState } from './types';
import { initialState } from './reducer';

export const channelHistoryPersist = (store: Store) => {
	store.subscribe(() => {
		const state = store.getState();
		const savedRevision = localStorage.getItem('channelHistoryRevision');
		if (state.channelHistory.revision !== savedRevision) {
			localStorage.setItem('channelHistory', JSON.stringify(state.channelHistory));
			localStorage.setItem('channelHistoryRevision', state.channelHistory.revision);
		}
	});
};

export const rehydrateChannelHistory = (): ChannelHistoryState => {
	try {
		const channelHistory = localStorage.getItem('channelHistory');
		if (!channelHistory) {
			return initialState;
		}
		return JSON.parse(channelHistory) as ChannelHistoryState;
	} catch (error) {
		return initialState;
	}
};
