import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { ExternalLink, HeadingBoundary, Headline, State } from '@panda/ui';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import classes from './SipgateStatusComponent.scss';
import {
	SipgateStatus,
	SipgateStatusIndicator,
	useSipgateStatus,
} from '../../../redux/slices/sipgatestatus';
import DropOut from '../../DropOut';
import { Translate, useTranslate } from '../../../redux/slices/translations';
import SipgateStatusLogo from './img/sipgate_status.svg';
import useKeyboardEvents from '../../hooks/useKeyboardEvents';
import useOutsideClick from '../../hooks/useOutsideClick';
import { trackClick } from '../../../third-party/mixpanel';
import { useSipgateDomain } from '../../../redux/modules/userinfo';
import { AreColorsInvertedContext } from '../../../navigation/SideNavigation/SideNavigation.utils';

const mapIndicatorToLK = (indicator: SipgateStatusIndicator) => {
	switch (indicator) {
		case 'minor':
			return 'SIPGATE_STATUS_MINOR';
		case 'major':
		case 'critical':
			return 'SIPGATE_STATUS_MAJOR';
		case 'maintenance':
			return 'SIPGATE_STATUS_MAINTENANCE';
		case 'none':
		default:
			return 'SIPGATE_STATUS_NONE';
	}
};

const mapIndicatorToHeadline = (
	indicator: SipgateStatusIndicator,
	data: SipgateStatus,
	translate: Translate
) => {
	if (indicator !== 'none') {
		return data.name;
	}

	return translate('SIPGATE_STATUS_NONE_DESCRIPTION');
};

const mapIndicatorToState = (indicator: SipgateStatusIndicator, translate: Translate) => {
	switch (indicator) {
		case 'minor':
			return (
				<State size="xsmaller" color="orange">
					{translate(mapIndicatorToLK(indicator))}
				</State>
			);
		case 'major':
		case 'critical':
			return (
				<State size="xsmaller" color="red">
					{translate(mapIndicatorToLK(indicator))}
				</State>
			);

		case 'maintenance':
			return (
				<State size="xsmaller" color="blue">
					{translate(mapIndicatorToLK(indicator))}
				</State>
			);
		case 'none':
		default:
			return (
				<State color="green" size="xsmaller">
					{translate(mapIndicatorToLK(indicator))}
				</State>
			);
	}
};

interface Props {
	variant?: 'dropdown' | 'button';
}

const SipgateStatusComponent = ({ variant = 'dropdown' }: Props) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);

	const translate = useTranslate();
	const { data: sipgateStatusData } = useSipgateStatus();
	const sipgateDomain = useSipgateDomain();

	const colorInverted = React.useContext(AreColorsInvertedContext);

	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);

	const { isDark } = useTheme();

	if (!sipgateStatusData || !sipgateDomain || sipgateDomain === 'sipgate.co.uk') {
		return null;
	}

	if (variant === 'button') {
		return (
			<a
				href="https://status.sipgate.de"
				target="_blank"
				className={classnames(
					classes.sipgatestatus,
					classes[sipgateStatusData.indicator],
					classes[variant],
					{
						[classes.colorInverted]: colorInverted,
					}
				)}
				rel="noreferrer"
			>
				{mapIndicatorToState(sipgateStatusData.indicator, translate)}
				{translate(mapIndicatorToLK(sipgateStatusData.indicator))}
			</a>
		);
	}

	return (
		<div
			className={classnames(
				classes.sipgatestatus,
				classes[sipgateStatusData.indicator],
				classes[variant],
				{
					[classes.noDescription]: !sipgateStatusData.body,
					[classes.noTimestamp]: !sipgateStatusData.updated_at,
				}
			)}
			ref={ref}
			data-testid="sipgate-status"
		>
			<button
				type="button"
				className={classes.statusButton}
				onClick={() => {
					trackClick('SipgateStatus Clicked', {
						isOpen: open,
						indicator: sipgateStatusData.indicator,
					});
					setOpen(!open);
				}}
				aria-controls="status-menu"
				aria-expanded={open}
				id="sipgate-status-button"
			>
				{mapIndicatorToState(sipgateStatusData.indicator, translate)}
				{translate(mapIndicatorToLK(sipgateStatusData.indicator))}
			</button>
			<DropOut id="status-menu" open={open} ariaLabelledBy="sipgate-status-button">
				<div className={classes.status}>
					<img
						className={classnames([classes.header, isDark && classes.inverted])}
						src={SipgateStatusLogo}
						alt=""
					/>
					<HeadingBoundary>
						<div className={classes.content}>
							<Headline className={classes.headline}>
								{mapIndicatorToHeadline(sipgateStatusData.indicator, sipgateStatusData, translate)}
							</Headline>
							<p className={classes.description}>{sipgateStatusData.body}</p>
							{sipgateStatusData.updated_at ? (
								<span className={classes.timestamp}>
									{DateTime.fromISO(sipgateStatusData.updated_at)
										.setLocale('de')
										.toLocaleString(DateTime.DATETIME_FULL)}
								</span>
							) : null}
						</div>
					</HeadingBoundary>
					<div className={classes.statusLink}>
						<ExternalLink to={sipgateStatusData.statusUrl} variant="normal" target="blank">
							{translate('SIPGATE_STATUS_LINK')}
						</ExternalLink>
					</div>
				</div>
			</DropOut>
		</div>
	);
};

export default SipgateStatusComponent;
