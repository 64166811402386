import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { SliceData, useAction } from '../../index';
import { DirectDial } from './types';
import { createDirectDial, deleteDirectDial, editDirectDial, fetchDirectDials } from './actions';

export const useDirectDials = (): SliceData<DirectDial[]> => {
	const dispatch = useDispatch();
	const directDials = useSelector(state => state.directDials);

	useEffect(() => {
		dispatch(fetchDirectDials());
	}, [dispatch]);

	if (directDials.fetched) {
		return { data: directDials.items, fetched: true };
	}

	return { data: null, fetched: false };
};

export const useDirectDialActions = () => {
	return {
		createDirectDial: useAction(createDirectDial),
		deleteDirectDial: useAction(deleteDirectDial),
		editDirectDial: useAction(editDirectDial),
	};
};
