import React from 'react';

import { Button } from '@panda/ui';
import { connect, ReduxProps } from '../../../redux';
import { ReduxState } from '../../../redux/types';
import classes from './CageDialog.scss';
import { buildAuthenticateUrl } from '../../../utils/authenticate';
import auth from '../../../utils/authenticate/auth';
import DeprecatedDialog from '../DeprecatedDialog';
import { TranslateProps, withTranslate } from '../../../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	links: state.links,
});

interface ExternalProps {
	children: React.ReactNode;
	showAdditionalOverlay?: boolean;
}

type Props = ExternalProps & TranslateProps & ReduxProps<typeof mapStateToProps>;

class CageDialog extends React.Component<Props> {
	public static defaultProps = {
		showAdditionalOverlay: true,
	};

	private getCageDialogLinks = () => {
		if (this.props.showAdditionalOverlay) {
			return (
				<div className={classes.cageDialog}>
					<Button size="xlarge" onClick={this.goToUserSettings}>
						{this.props.translate('USERSETTINGS_LINK')}
					</Button>
					<Button size="xlarge" onClick={this.logoutUser}>
						{this.props.translate('LOGOUT')}
					</Button>
				</div>
			);
		}
	};

	private goToUserSettings = () => {
		window.location.href = buildAuthenticateUrl(
			this.props.links.items.legacyTokenAuthenticateUrl,
			this.props.links.items.userSettingsUrl
		);
	};

	private logoutUser = () => {
		auth.logoutAndRedirectToLogoutPage(this.props.links.items);
	};

	public render() {
		return (
			<DeprecatedDialog additionalOverlayContent={this.getCageDialogLinks()}>
				{this.props.children}
			</DeprecatedDialog>
		);
	}
}

export default withTranslate(connect(mapStateToProps)(CageDialog));
