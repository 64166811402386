import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ReduxProps } from '../../../redux';
import {
	fetchIdentityVerification,
	setAfterVideoIdent,
} from '../../../redux/modules/identityVerification';
import { fetchAccount } from '../../../redux/slices/account';
import DialogPageWrapper from '../DialogPageWrapper';
import AfterVideoIdentDialog from './pages/AfterVideoIdentDialog';
import { StartVideoIdentDialog } from './pages/StartVideoIdentDialog';
import VideoIdentAbortedDialog from './pages/VideoIdentAbortedDialog';
import VideoIdentFailedDialog from './pages/VideoIdentFailedDialog';
import VideoIdentSuccessDialog from './pages/VideoIdentSuccessDialog';
import { currentPathWithoutDialog } from '../../../routes/paths';
import { ReduxState } from '../../../redux/types';
import { Translate } from '../../../redux/slices/translations';
import { WithDialogProps, withDialogs } from '../../../routes/paths/dialogs';
import { TranslateProps, withTranslate } from '../../../helpers/withTranslations';

const START_VIDEO_IDENT = 1;
const AFTER_VIDEO_IDENT = 2;
const VIDEO_IDENT_SUCCESS = 3;
const VIDEO_IDENT_FAILED = 4;
const VIDEO_IDENT_ABORTED = 5;

const FIRST_PAGE = START_VIDEO_IDENT;

const POLLING_INTERVAL = 5 * 1000;

interface State {
	intervalId: number | null;
	page: number;
}

type ExternalProps = {
	translate: Translate;
};

const mapStateToProps = (state: ReduxState) => ({
	identityVerification: state.identityVerification,
	appProperties: state.appProperties,
	userInfo: state.userinfo,
	restrictions: state.restrictions,
});

const mapDispatchToProps = {
	setAfterVideoIdent,
	fetchIdentityVerification,
	fetchAccount,
};

type Props = ExternalProps &
	WithDialogProps &
	TranslateProps &
	ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> &
	RouteComponentProps;

class IdentityVerificationDialogContent extends React.Component<Props> {
	public state: State = {
		intervalId: null,
		page: FIRST_PAGE,
	};

	public componentDidMount() {
		this.showDialogPageDependingOnState();
		this.startPolling();
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.identityVerification !== prevProps.identityVerification) {
			this.showDialogPageDependingOnState();
		}
	}

	public componentWillUnmount() {
		this.stopPolling();
	}

	private changeToAfterVideoIdentPage = () => {
		this.setState({ page: AFTER_VIDEO_IDENT });
	};

	private changeToVideoIdentSuccessPage = () => {
		this.setState({ page: VIDEO_IDENT_SUCCESS });
		this.stopPolling();
	};

	private changeToVideoIdentFailedPage = () => {
		this.setState({ page: VIDEO_IDENT_FAILED });
	};

	private changeToVideoIdentAbortedPage = () => {
		this.setState({ page: VIDEO_IDENT_ABORTED });
	};

	private closeSuccessDialog = () => {
		this.props.setAfterVideoIdent(false);
		this.props.history.replace(currentPathWithoutDialog());
	};

	private startPolling = () => {
		if (this.state.intervalId !== null) {
			return;
		}

		const TRY_DELAY_MODIFIER = 2;
		this.setState({
			intervalId: window.setInterval(() => {
				this.props.fetchIdentityVerification(this.props.appProperties);
			}, POLLING_INTERVAL / TRY_DELAY_MODIFIER),
		});
	};

	private stopPolling = () => {
		if (this.state.intervalId !== null) {
			window.clearInterval(this.state.intervalId);

			this.setState({
				intervalId: null,
			});
		}
	};

	private showDialogPageDependingOnState() {
		if (!this.props.identityVerification.data) {
			return;
		}

		if (this.props.identityVerification.data.verified) {
			this.props.fetchAccount(true);
			this.changeToVideoIdentSuccessPage();
		} else if (this.props.identityVerification.data.data.state === 'FAILED') {
			this.changeToVideoIdentFailedPage();
		} else if (this.props.identityVerification.data.data.state === 'ABORTED') {
			this.changeToVideoIdentAbortedPage();
		} else if (
			this.props.identityVerification.data.data.state === 'REVIEW_PENDING' ||
			this.props.identityVerification.videoIdentCompletedWithinSession
		) {
			this.changeToAfterVideoIdentPage();
		}
	}

	public render() {
		// uncomment this when idnow is some how not working
		// const showIdnowDelayWarning = true;
		const showIdnowDelayWarning = this.props.identityVerification.error === 'UNAVAILABLE';

		if (!this.props.userInfo.fetched) {
			return null;
		}

		return (
			<DialogPageWrapper
				currentPage={this.state.page}
				pages={[
					<StartVideoIdentDialog
						translate={this.props.translate}
						videoIdentLink={this.props.identityVerification.data?.data?.externalVerificationLink}
						autoIdentLink={
							this.props.identityVerification.data?.data?.externalVerificationLinkAutoident
						}
						showIdnowDelayWarning={showIdnowDelayWarning}
						verificationNotFound={this.props.identityVerification.error === 'NOT_FOUND'}
						userInfo={this.props.userInfo.data}
					/>,
					<AfterVideoIdentDialog translate={this.props.translate} />,
					<VideoIdentSuccessDialog
						translate={this.props.translate}
						onClose={this.closeSuccessDialog}
					/>,
					<VideoIdentFailedDialog />,
					<VideoIdentAbortedDialog
						translate={this.props.translate}
						showIdnowDelayWarning={showIdnowDelayWarning}
						videoIdentLink={this.props.identityVerification.data?.data?.externalVerificationLink}
						autoIdentLink={
							this.props.identityVerification.data?.data?.externalVerificationLinkAutoident
						}
					/>,
				]}
			/>
		);
	}
}

export default withDialogs(
	withRouter(
		withTranslate(connect(mapStateToProps, mapDispatchToProps)(IdentityVerificationDialogContent))
	)
);
